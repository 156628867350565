<template>
  <div class="fill-height">
    <IproMain
        jenis="POTENSI"
    ></IproMain>
  </div>
</template>

<script>
import IproMain from "../../components/public_potensi_kawasan/PotensiMain";

export default {
  name: "PotensiView",
  components:{
    IproMain
  }
}
</script>

<style scoped>

</style>